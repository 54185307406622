( function ( $ ) {
	
	$( document ).ready( function() {
		
		$( '#footer-control' ).click( function() {
			
			var control = $( this );
			var footer_elem = $( '#footer-inner' );
			var footer_wrapper = $( '#footer-wrapper' );
			
			if ( footer_wrapper.hasClass( 'open' ) ) {
				
				footer_wrapper.removeClass( 'open' );
				
			} else {
				
				footer_wrapper.addClass( 'open' );
				
			}
			
		} );
		
	} );

} )( jQuery );
