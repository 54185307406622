( function ( $ ) {
	
	$( document ).ready( function() {
		
		$( document ).foundation();
		new WOW().init();
		
	} );

} )( jQuery );
